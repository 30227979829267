import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../styles/homeSlider.css";

export default function HomeSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    
    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/rayas.png" alt="Rayas"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">ALIMENTACIÓN DE RAYAS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className="slider_element__t3">Ven y emociónate en esta divertida experiencia en el hábitat Bahía de Rayas. ¡Tienes que vivirlo!</div>
                            <a href="#" className="slider_element__btn">MAS INFORMACIÓN</a>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/pinguinos.png" alt="Pinguinos"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">INTERACCIÓN CON PINGÜINOS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className="slider_element__t3">En esta increíble experiencia podrás verlos frente a ti, alimentarlos y tendrás la oportunidad de llevar una linda fotografía.</div>
                            <a href="#" className="slider_element__btn">MAS INFORMACIÓN</a>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/agapornias.png" alt="Agapornias"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">ALIMENTACIÓN DE AGAPORNIAS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className="slider_element__t3">Esta es una experiencia llena de colores que no te puedes perder. Actividad para toda la familia.</div>
                            <a href="#" className="slider_element__btn">MAS INFORMACIÓN</a>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}