import { NavbarNew, Sidebar } from "../components/Navigation";
import Header from "../components/Header";
import { HeaderVideo } from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import { useState } from "react";
import ScrollEvents from "../components/ScrollEvents";

import HomeSlider from "../components/HomeSlider";
import "./../styles/homeNew.css";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    { /* Entradas de texto */ }
    const historia__title = useRef(null);
    const historia__des_anim1 = useRef(null);
    const historia__des_anim2 = useRef(null);
    const tarifas_anim1 = useRef(null);
    const tarifas_anim2 = useRef(null);
    const tarifas_anim3 = useRef(null);
    const tarifas_anim4 = useRef(null);
    useEffect(() => {
        gsap.from(historia__title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.historia__title' });
    }, []);
    useEffect(() => {
        gsap.from(historia__des_anim1.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.historia__des_anim1' });
    }, []);
    useEffect(() => {
        gsap.from(historia__des_anim2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.historia__des_anim2' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim1.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim1' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim2' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim3.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim3' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim4.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim4' });
    }, []);

    { /* Entrada de bloques por izquierda */ }
    const fromLeft = useRef(null);
    const fromLeft2 = useRef(null);
    useEffect(() => {
        gsap.to(fromLeft.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromLeft' });
    }, []);
    useEffect(() => {
        gsap.to(fromLeft2.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromLeft2' });
    }, []);

    { /* Entrada de bloques por derecha */ }
    const fromRight = useRef(null);
    const fromRight2 = useRef(null);
    useEffect(() => {
        gsap.to(fromRight.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromRight', });
    }, []);
    useEffect(() => {
        gsap.to(fromRight2.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromRight2', });
    }, []);

    { /* Movimiento medusa */ }
    const exhibiciones_medusa = useRef(null);
    const timeline_medusa = useRef();
    useEffect(() => {
        timeline_medusa.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.exhibiciones_medusa',
                //markers: true,
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_medusa.current.to(exhibiciones_medusa.current, {y: -120, duration: 2,});
    });

    { /* Movimiento tiburón */ }
    const tiburon = useRef(null);
    const timeline_tiburon = useRef();
    useEffect(() => {
        timeline_tiburon.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.tiburon',
                //markers: true,
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_tiburon.current.to(tiburon.current, {x: 100, y: 120, duration: 3,});
    });

    { /* Movimiento constante */ }
    const exhibiciones_des_con_el__icon = useRef(null);
    useEffect(() => {
        gsap.from(exhibiciones_des_con_el__icon.current, { duration: 2, opacity: 0, x: -40, ease: 'power2.out', repeat: -1, repeatDelay: .2 });
    }, []);

    return (
        <>
            {/*
            <Modal open={ openModal } onClose={ () => {setOpenModal(false);allowScroll();} } />
            */}
            
            <NavbarNew engLink={ "/eng/" }/>
            <Sidebar engLink={ "/eng/" }/>
            <HeaderVideo routeName="home" altName="Gran Acuario Mazatlán Render" classes="home_header"/>

            { /* SOMOS LLENOS DE VIDA */ }
            <div className="vida section">
                <img className="vida__bg" src="/images/home/contenedores/background.png" alt="Background" />
                <div className="content">
                    <div className="vida_title">
                        <div className="vida_title__t1">SOMOS LLENOS DE VIDA</div>
                        <div className="vida_title__sep"></div>
                    </div>
                    <div className="vida_elements">
                        <div className="vida_element">
                            <img src="/images/home/contenedores/habitats_icon.png" alt="Habitats" className="vida_element__img" />
                            <div className="vida_element__t1">+ DE 30 HÁBITATS</div>
                            <div className="vida_element__t2">Tendras acceso a los más de 30 maravillos hábitats.</div>
                        </div>
                        <div className="vida_element">
                            <img src="/images/home/contenedores/ventana_icon.png" alt="Ventana" className="vida_element__img" />
                            <div className="vida_element__t1">IMPRESIONANTE VENTANA OCEÁNICA</div>
                            <div className="vida_element__t2">La ventana oceánica más grande de LATAM.</div>
                        </div>
                        <div className="vida_element">
                            <img src="/images/home/contenedores/actividades_icon.png" alt="Actividades" className="vida_element__img" />
                            <div className="vida_element__t1">ACTIVIDADES</div>
                            <div className="vida_element__t2">Grandiosas actividades para toda la familia.</div>
                        </div>
                    </div>
                </div>
            </div>

            { /* GRAN ACUARIO MAZATLAN */ }
            <div className="acuario section">
                <div className="acuario_img">
                    <img src="/images/home/contenedores/mujer_pinguino.png" alt="Mujer con pinguino"/>
                </div>
                <div className="acuario_des">
                    <div className="acuario_des__t1">Gran Acuario Mazatlán, es el más grande de México y más importante en Latinoamérica, dedicado al Mar de Cortés. </div>
                    <div className="acuario_des__t2">Con un enfoque en la educación, la investigación y divulgación del conocimiento, marcará un referente en los acuarios del mundo. Ubicado estratégicamente en el Parque Central, Gran Acuario Mazatlán abre todos los días.</div>
                    <div className="acuario_des__t3">HORARIO</div>
                    <div className="acuario_des__t4"><img src="/images/home/contenedores/horario_icon.png" alt="Reloj icon"/> 10:00 AM  - 06:00 PM</div>
                    <div className="acuario_des__t3">CIERRE DE TAQUILLA</div>
                    <div className="acuario_des__t4"><img src="/images/home/contenedores/horario_icon.png" alt="Reloj icon"/> 04:00 PM</div>
                </div>
            </div>

            { /* ACTIVIDADES */ }
            <div className="grandiosas section">
                <div className="content">
                    <div className="grandiosas_title">
                        <div className="grandiosas_title__t1">ACTIVIDADES</div>
                        <div className="grandiosas_title__t2">Grandiosas actividades para disfrutar en el Gran Acuario, para toda la familia.</div>
                    </div>
                    <HomeSlider/>
                </div>
            </div>

            { /* PLANIFICA TU VISITA */ }
            <div className="planifica section">
                <img className="planifica__bg" src="/images/home/contenedores/background2.svg"/>
                <div className="content">
                    <div className="planifica_des">
                        <div className="planifica_des_container">
                            <div className="planifica_des__t1">PLANIFICA TU VISITA</div>
                            <div className="planifica_des__sep"></div>
                            <div className="planifica_des__t2">¡No te pierdas nada! Descubre todo lo que te ofrece Gran Acuario Mazatlán: presentaciones de animales, más de 30 hábitats, una densa vegetación y mucho más.</div>
                            <div className="planifica_des__btn">MÁS INFORMACIÓN</div>
                        </div>
                    </div>
                    <div className="planifica_img">
                        <img src="/images/home/contenedores/lobo_marino.png"/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}