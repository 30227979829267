import { NavbarEng, SidebarEng } from "../components/Navigation"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import entradas from "./../styles/entradas.module.css"
import cx from "classnames"
import FooterEng from "../components/FooterEng"
import { Accordion } from "react-accessible-accordion"
import { AccordionItemEntradas, AccordionItemEntradasND, AccordionItemEspeciales, AccordionItemEventos } from "../components/AccordionItem"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);


export default function EntradasEng() {
    const tiburon = useRef(null);
    const timeline_tiburon = useRef();
    useEffect(() => {
        timeline_tiburon.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.eventos_tiburon',
                //markers: true,
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_tiburon.current.to(tiburon.current, {x: 120, duration: 2,});
    });

    return (
        <>
            <NavbarEng espLink={ "/entradas" }/>
            <SidebarEng espLink={ "/entradas" }/>
            <Header routeName="entradas" altName="Niño mirando pecera" headerTitle="TICKETING" titleClasses="default"/>

            {/* Entradas */}
            <div className={ entradas.entradas + " section" }>
                <div className={ entradas.entradas_content + ' content' }>
                    <div className={ entradas.entradas_des }>
                        <div className={ cx(entradas.entradas_des__t1, entradas.title) + ' title' }>TICKETS MAY BE PURCHASED ONLINE, AVOIDING LINES, AND WITH A SPECIAL ENTRANCE OR DIRECT PASSAGE THROUGH THE TICKET OFFICE</div>
                        <div className={ cx(entradas.entradas_des__t2) + ' title' }>Our schedule is from 10:00am to 6:00pm</div>
                        <div className={ cx(entradas.entradas_des__t2) + ' title' }>Ticket office closes at 4:00pm</div>
                    </div>
                    <div className={ entradas.entradas_opt }>
                        <div className={ entradas.entradas_opt_content }>
                            <Accordion className={ entradas.accordion_entradas }>
                                <AccordionItemEntradas title="Adult" price="470mxn" des="You will have access to the wonderful 30 habitats found in our building, as well as what the surrounding area has to offer. You will also have the opportunity to attend the Bird Presentation in the open-air auditorium. General admission 12 years and older."/>
                                <AccordionItemEntradas title="Child" price="370mxn" des="From 4 to 11 years old. Children under 4 years old free admission upon presentation of proof of age. The accompanying adult must present a valid INE card with address in Mazatlan."/>
                                <AccordionItemEntradas title="Seniors 65" price="360mxn" des="Seniors over 65 years of age, pensioners and/or retirees. It is mandatory to present a document that proves it."/>
                                <AccordionItemEntradasND title="Disabled" price="0"/>
                                <AccordionItemEntradas title="Groups" price="" des="20 people or more. Book at: " link="ventasgrupos@granacuario.com"/>
                            </Accordion>
                            <a href="https://tickets.granacuario.com/mx" className={ entradas.entradas_des__btn }>Buy tickets</a>
                            <div className={ cx(entradas.entradas_des__t3) + ' title' }>For special offers, ask directly on ticket office or send us an email <a href="mailto:ventasgrupos@granacuario.com">ventasgrupos@granacuario.com</a></div>
                        </div>
                        {/*
                        <div className={ entradas.entradas_opt_content }>
                            <div className={ entradas.entradas_opt_title }>General ticket</div>
                            <Accordion className={ entradas.accordion_entradas }>
                                <AccordionItemEntradas title="Adult" price="380mxn" des="You will have access to the wonderful 26 habitats found in our building, as well as what the surrounding area has to offer. You will also have the opportunity to attend the Bird Presentation in the open-air auditorium. General admission 12 years and older."/>
                                <AccordionItemEntradas title="Child" price="280mxn" des="From 3 to 12 years old. Children under 3 years old free admission upon presentation of proof of age. The accompanying adult must present a valid INE card with address in Mazatlan."/>
                                <AccordionItemEntradas title="Seniors 65" price="270mxn" des="Seniors over 65 years of age, pensioners and/or retirees. It is mandatory to present a document that proves it."/>
                                <AccordionItemEntradasND title="Disabled" price="0"/>
                                <AccordionItemEntradas title="Groups" price="" des="20 people or more. Book at: " link="ventasgrupos@granacuario.com"/>
                            </Accordion>
                            <a href="https://tickets.granacuario.com/mx" className={ entradas.entradas_des__btn }>Available at ticket office </a>
                        </div>
                        */}
                        {/*
                        <div className={ entradas.donar }>
                            <a href="/" className={entradas.donar_btn }>Donar</a>
                            <div className={entradas.donar_des }>Dona $ 5.00 por boleto<br/>para el Centro de Investigación</div>
                        </div>
                        */}
                    </div>
                </div>
            </div>

            {/* Membresías */}
            <div className={ entradas.especiales + " section" }>
                <div className={ entradas.especiales_content + ' content'}>
                    <div className={ entradas.especiales_des }>
                        <div className={ cx(entradas.title, entradas.especiales_des__title, "title") }>MEMBERSHIP</div>
                    </div>
                    <div className={ entradas.especiales_opt }>
                        <Accordion>
                            <AccordionItemEspeciales title="Adult annual pass" price="1,500mxn" des="The individual pass will allow you to enter the Gran Acuario Mazatlán Mar de Cortés as many times as you wish during 365 days of the year."/>
                            {/*<AccordionItemEspeciales title="Premium anual" price="" des="Disfruta de los beneficios de tu pase anual y beneficios adicionales, como un 10% de descuento en compras y consumo en el acuario. En tu primera visita disfruta del recorrido calypso (más información en actividades) y de la oportunidad de alimentar a las especies de bahía de rayas. Quienes adquieran la membresía anual antes de la apertura podrán participar en un recorrido especialmente diseñado para ellos."/>*/}
                            <AccordionItemEspeciales title="Child family pass" price="1,000mxn" des="From 4 to 11 years old."/>
                            {/*<AccordionItemEspeciales title="Premium anual familiar" price="" des="Beneficios del premium anual para un máximo de 4 personas."/>*/}
                        </Accordion>
                        {/*<a href="https://tickets.granacuario.com/mx/1083-pase-anual-y-membresia" className={ entradas.entradas_des__btn }>Compra membresías</a>*/}
                        <a href="https://tickets.granacuario.com/1083-pase-anual-y-membresia" target="_blank" className={ entradas.entradas_des__btn }>Buy membership</a>
                    </div>
                </div>
            </div>

            {/* Eventos
            <div className={ entradas.eventos + ' section'}>
                <div className={ entradas.eventos_content + ' content'}>
                    <div className={ entradas.eventos_des }>
                        <div className={ entradas.title + " title" }>EXPERIENCIAS PREMIUM</div>
                        <div className={ entradas.des }>Todas las experiencias premium incluyen entrada general al Gran Acuario.</div>
                    </div>
                    <div className={ entradas.eventos_opt }>
                        <Accordion preExpanded={['a']}>
                            <AccordionItemEventos uuid="a" title="Recorrido Calypso" price="550" des="Adéntrate a las zonas de acceso especial y aprende con nuestros expertos. En el recorrido Calypso conocerás el área de preparación de alimento, te impactarás con los equipos de soporte de vida y podrás observar la alimentación de los tiburones. A partir de 12 años."/>
                            <AccordionItemEventos uuid="b" title="Inmersión con tiburones gata" price="570" des="¡Anímate a interactuar de cerca con tiburones! Si eres mayor de 8 años, podrás disfrutar de una experiencia única e interesante en el hábitat de los tiburones gata."/>
                            <AccordionItemEventos uuid="c" title="Nado con tiburones" price="2,890" des="Si eres mayor de 18 años y cuentas con certificación de buceo vigente, es tu oportunidad de disfrutar del nado con tiburones. Sumérgete con estos maravillosos habitantes del mar de Cortés y acompáñalos en su majestuoso nado en el hábitat oceánico."/>
                            <AccordionItemEventos uuid="d" title="Alimentación de rayas" price="470" des="Vive la diversión de alimentar a las rayas y aprende datos curiosos sobre estos interesantes peces."/>
                            <AccordionItemEventos uuid="e" title="Alimentación de tiburones" price="570" des="Sube a lo más alto y admira la imponente belleza del corazón de nuestro acuario, donde podrás tener la experiencia de alimentar a los majestuosos tiburones."/>
                        </Accordion>
                        <a href="https://tickets.granacuario.com/mx/982-experiencias-premium" className={ entradas.entradas_des__btn }>Compra experiencias premium</a>
                        <div className={ entradas.entradas_des__btn }>Proximamente</div>
                    </div>
                </div>
                <img ref={ tiburon } className={ entradas.eventos_bg + ' eventos_tiburon' } src="/images/entradas/tiburones.png" srcSet="/images/entradas/tiburones.webp" alt="Tiburones"/>
                <img className={ entradas.eventos_nina } src="/images/entradas/nina.png" srcSet="/images/entradas/nina.webp" alt="Tiburones"/>
            </div>
            */}

            {/* Patrocinador
            <div className={ cx(entradas.patrocinador, 'section')}>
                <div className={ cx(entradas.patrocinador_content, 'content')}>
                    <div className={ entradas.patrocinador_des }>
                        <div className={ entradas.patrocinador_des__t1 }>¡Reciba todas las novedades, promociones y eventos especiales del Gran Acuario de Mazatlán en su correo electrónico!</div>
                        <div className={ entradas.patrocinador_des__link }>Suscríbete al boletín mensual</div>
                        <div className={ entradas.patrocinador_des__link }>Introduce tu correo electrónico</div>
                        <div className={ entradas.patrocinador_des__link }>Política de privacidad</div>
                        <div className={ entradas.patrocinador_des__link }>Política de cookies</div>
                    </div>
                    <div className={ entradas.patrocinador_img }>
                        <img src="/images/entradas/coca-cola_logo.png" srcSet="/images/entradas/coca-cola_logo.webp" alt="Coca Cola Logo"/>
                        <div className={ entradas.patrocinador_img__des }>SPONSOR OFICIAL</div>
                    </div>
                </div>
                <Regresar des="Inicio" link="/"/>
            </div>
            */}

            <FooterEng/>
        </>
    )
}