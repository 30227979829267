import React from "react";
import ReactDOM from "react-dom/client";

import { createBrowserRouter, RouterProvider, createHashRouter } from "react-router-dom";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

/* Páginas */
import Exhibiciones from "./pages/exhibiciones";
import ExhibicionesEng from "./pages/exhibicionesEng";

import HomeNew from "./pages/homeNew";
import Home from "./pages/home";
import HomeEng from "./pages/homeEng";

import Tierra from "./pages/tierra";
import TierraEng from "./pages/tierraEng";

import Costa from "./pages/costa";
import CostaEng from "./pages/costaEng";

import Mar from "./pages/mar";
import MarEng from "./pages/marEng";

import Especie from "./pages/especie";
import EspecieEng from "./pages/especieEng";

import Habitat from "./pages/habitat";
import HabitatEng from "./pages/habitatEng";

import Actividades from "./pages/actividades";
import ActividadesEng from "./pages/actividadesEng";

import Fiestas from "./pages/fiestas";
import FiestasEng from "./pages/fiestasEng";

import Entradas from "./pages/entradas";
import EntradasEng from "./pages/entradasEng";

import Educacion from "./pages/educacion";
import EducacionEng from "./pages/educacionEng";

import Investigacion from "./pages/investigacion";
import InvestigacionEng from "./pages/investigacionEng";

import Contacto from "./pages/contacto";
import ContactoEng from "./pages/contactoEng";

import Visita from "./pages/visita";
import VisitaEng from "./pages/visitaEng";

import Grupos from "./pages/grupos";
import GruposEng from "./pages/gruposEng";

import Faq from "./pages/faq";
import FaqEng from "./pages/faqEng";

import Live from "./pages/live";

import Mapa from "./pages/mapa";
import { MapaEng, MapaEngEscaleras, MapaEngTunel } from "./pages/mapaEng";
/* Final páginas */

import "./styles/global.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/new" element={ <HomeNew/> }/>
                <Route path="/" element={ <Home/> }/>
                <Route path="/eng/" element={ <HomeEng/> }/>
                <Route path="/entradas" element={ <Entradas/> }/>
                <Route path="/eng/ticketing" element={ <EntradasEng/> }/>
                <Route path="/habitats" element={ <Exhibiciones/> }/>
                <Route path="/eng/habitats" element={ <ExhibicionesEng/> }/>
                <Route path="/exhibiciones" element={ <Exhibiciones/> }/>
                <Route path="/educacion" element={ <Educacion/> }/>
                <Route path="/eng/education" element={ <EducacionEng/> }/>
                <Route path="/investigacion" element={ <Investigacion/> }/>
                <Route path="/eng/research" element={ <InvestigacionEng/> }/>
                <Route path="/actividades" element={ <Actividades/> }/>
                <Route path="/eng/activities" element={ <ActividadesEng/> }/>
                <Route path="/actividades/fiestas" element={ <Fiestas/> }/>
                <Route path="eng/activities/social-events" element={ <FiestasEng/> }/>
                <Route path="/grupos" element={ <Grupos/> }/>
                <Route path="/eng/groups" element={ <GruposEng/> }/>
                <Route path="/visita" element={ <Visita/> }/>
                <Route path="/eng/visit" element={ <VisitaEng/> }/>
                <Route path="/contacto" element={ <Contacto/> }/>
                <Route path="/eng/contact" element={ <ContactoEng/> }/>
                <Route path="/faq" element={ <Faq/> }/>
                <Route path="/eng/faq" element={ <FaqEng/> }/>
                <Route path="/live" element={ <Live/> }/>
                {/* Costa paths */}
                <Route path="/habitats/costa" element={ <Costa/> }/>
                <Route path="/eng/habitats/coast" element={ <CostaEng/> }/>
                <Route path="/exhibiciones/costa.html" element={ <Costa/> }/>
                <Route path="/habitats/costa/:habitat" element={ <Habitat/> }/>
                <Route path="/eng/habitats/coast/:habitat" element={ <HabitatEng/> }/>
                <Route path="/exhibiciones/costa.html/:habitat" element={ <Habitat/> }/>
                <Route path="/habitats/costa/:habitat/:especie" element={ <Especie/> }/>
                <Route path="/eng/habitats/coast/:habitat/:especie" element={ <EspecieEng/> }/>
                <Route path="/exhibiciones/costa.html/:habitat/:especie" element={ <Especie/> }/>
                {/* Tierra paths */}
                <Route path="/habitats/tierra" element={ <Tierra/> }/>
                <Route path="/eng/habitats/land" element={ <TierraEng/> }/>
                <Route path="/exhibiciones/tierra.html" element={ <Tierra/> }/>
                <Route path="/habitats/tierra/:habitat" element={ <Habitat/> }/>
                <Route path="/eng/habitats/land/:habitat" element={ <HabitatEng/> }/>
                <Route path="/exhibiciones/tierra.html/:habitat" element={ <Habitat/> }/>
                <Route path="/habitats/tierra/:habitat/:especie" element={ <Especie/> }/>
                <Route path="/eng/habitats/land/:habitat/:especie" element={ <EspecieEng/> }/>
                <Route path="/exhibiciones/tierra.html/:habitat/:especie" element={ <Especie/> }/>
                {/* Mar paths */}
                <Route path="/habitats/mar" element={ <Mar/> }/>
                <Route path="/eng/habitats/sea" element={ <MarEng/> }/>
                <Route path="/exhibiciones/mar.html" element={ <Mar/> }/>
                <Route path="/habitats/mar/:habitat" element={ <Habitat/> }/>
                <Route path="/eng/habitats/sea/:habitat" element={ <HabitatEng/> }/>
                <Route path="/exhibiciones/mar.html/:habitat" element={ <Habitat/> }/>
                <Route path="/habitats/mar/:habitat/:especie" element={ <Especie/> }/>
                <Route path="/eng/habitats/sea/:habitat/:especie" element={ <EspecieEng/> }/>
                <Route path="/exhibiciones/mar.html/:habitat/:especie" element={ <Especie/> }/>
                {/* Mapas paths */}
                <Route path="/mapa/" element={ <Mapa/> }/>
                <Route path="/eng/map/" element={ <MapaEng/> }/>
                <Route path="/eng/mapa-escaleras/" element={ <MapaEngEscaleras/> }/>
                <Route path="/eng/mapa-tunel/" element={ <MapaEngTunel/> }/>
                {/* Error 404 */}
                <Route path="*" element={ <div>Página no encontrada</div> }/>
            </Routes>
        </BrowserRouter>
    </>
);